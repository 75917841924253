.page {
  height: 100vh; // fallback for `svh` units
  height: 100svh;
  overflow: hidden;
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: 60px 1fr;
  grid-template-areas:
    'header main'
    'sidebar main';
}

.aside {
  overflow: auto;
  grid-area: sidebar;
  mask: linear-gradient(180deg, transparent 0, rgba(0, 0, 0, 1) 50px, rgba(0, 0, 0, 1) calc(100% - 50px), transparent 100%);
  padding: 0 0 0 20px;

  // hide scrollbar: Firefox / IE
  scrollbar-width: none;
  -ms-overflow-style: none;
  // hide scrollbar: Chrome / Safari
  &::-webkit-scrollbar {
    display: none;
  }
}

.content {
  grid-area: main;
  position: relative;
}

// SPINNER
// TODO: move it to general
.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: var(--yoc-white);
}

.mobile.page {
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'header'
    'sidebar';
  grid-template-columns: 1fr;

  & .aside {
    padding: 0 14px;
    mask: none;
  }
}

// NEUTRAL THEME
:global(.neutral) {
  .content {
    grid-area: header / 2 / main / 2;
  }
}
