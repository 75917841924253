/* CONTAINER */
.container {
  position: absolute;
  inset: 15px;
  overflow: hidden;

  /* ??*/
  &.h-space {
    mask: linear-gradient(90deg, transparent 0, rgba(0, 0, 0, 1) 5%, rgba(0, 0, 0, 1) 95%, transparent 100%);
    -webkit-mask: linear-gradient(90deg, transparent 0, rgba(0, 0, 0, 1) 5%, rgba(0, 0, 0, 1) 95%, transparent 100%);
  }
  &.v-space {
    mask: linear-gradient(0deg, transparent 0, rgba(0, 0, 0, 1) 5%, rgba(0, 0, 0, 1) 95%, transparent 100%);
    -webkit-mask: linear-gradient(0deg, transparent 0, rgba(0, 0, 0, 1) 5%, rgba(0, 0, 0, 1) 95%, transparent 100%);
  }

  .ref-container {
    position: absolute;
    inset: 70px 40px;
  }
}

.dmexco .ref-container {
  inset: 0;
}

.neutral .ref-container {
  inset: 100px 40px;
}

/* QR CODE */
.qr-link {
  position: fixed;
  right: 30px;
  bottom: 5vh;
  z-index: 2;
}

.hidden {
  display: none;
}
