// DEFAULT THEME
:global(.default) {
  .menu {
    margin: clamp(30px, 5vh, 15vh) 0;
    padding: 0;
    list-style: none;
    user-select: none;
  }

  .title {
    display: flex;
    align-items: center;
    padding: 0 30px 0 19px;
    font: var(--page-font-second-size) / 54px var(--page-font);
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    border-radius: var(--border-radius);
    transition: all 0.3s ease;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      color: var(--new-yoc-light-turquoise);
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 10px;
      width: 12px;
      height: 12px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' viewBox='0 0 24 24'%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m5 16 7-7 7 7'/%3E%3C/svg%3E")
        no-repeat;
      transform: translateY(-50%) rotate(180deg);
      transition: transform 0.3s ease;
    }
  }

  .list {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    font-size: var(--page-font-third-size);
    background-color: var(--new-yoc-grey);
    border-radius: 0 0 10px 10px;
  }

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    gap: 10px;
    height: 60px;
    padding: 0 15px;
    margin: 11px 8px 8px;
    text-shadow: 1px 1px 3px rgba(8, 37, 89, 0.25);
    cursor: pointer;
    background: var(--yoc-blue);
    transition: box-shadow 0.3s ease;
    border-radius: var(--border-radius);

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        box-shadow: inset 0 0 0 2px var(--new-yoc-light-grey);
      }
    }

    &.active {
      box-shadow: inset 0 0 0 2px var(--new-yoc-turquoise);
    }

    /* LOGO */
    &::before {
      content: '';
      background: var(--logo-url) no-repeat 50% / contain;
      height: 46px;
      width: 65px;
    }

    /* Divider */
    &::after {
      content: '';
      position: absolute;
      height: 1px;
      background: var(--device-frame-outer-background);
      left: 6px;
      right: 6px;
      top: -6px;
    }

    &:first-child {
      margin-top: 8px;

      &::after {
        content: none;
      }
    }
  }

  .wrapper {
    display: grid;
    grid-template-rows: 0fr;
    overflow: hidden;
    transition: grid-template-rows 0.3s ease;
  }

  .expanded {
    .title {
      background: linear-gradient(to right, var(--new-yoc-turquoise) 5px, transparent 5px) no-repeat;

      &::after {
        transform: translateY(-50%) rotate(0);
      }
    }

    .wrapper {
      grid-template-rows: 1fr;
    }
  }

  .mobile {
    margin: 15px 0;
  }
}

// TODO: Add Neutral Theme
