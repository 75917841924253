/*NEUTRAL THEME*/

body.neutral {
  /* Palette */
  --color-basic: #a5a5a5;
  --color-accent: #0ba7ff;

  /* Page */
  --page-font: Muli, Arial, Helvetica, sans-serif;
  --page-font-second: Muli, Arial, Helvetica, sans-serif;
  --page-font-second-size: clamp(10px, 4vw, 22px);
  --page-color: #737373;
  --page-color-accent: #737373;
  --page-background: #fff;

  /* Device Frames */
  --device-frame-outer-border: #737373;
  --device-frame-inner-background: #f2f2f2;
  --device-frame-outer-background: #a5a5a5;
  --device-frame-inner-details: #ccc;

  color: var(--page-color);
  font: clamp(20px, 4vw, 32px) var(--page-font-second);
  background: var(--page-background);
  visibility: visible;
}
