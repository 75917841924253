.nav {
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translate(0, -50%);
  z-index: 99;
  background: var(--new-yoc-grey);
  border-radius: var(--border-radius);
  font: bold clamp(12px, 2vw, 14px) / 40px var(--page-font);

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
    overflow: hidden;
  }

  li {
    display: inline-block;
    vertical-align: top;
    min-width: 49px;

    &:not(:last-child) {
      padding: 0 8px 0 0;
    }

    a {
      display: block;
      border-radius: var(--border-radius);
      text-align: center;
      transition: all 0.3s ease-in;
      padding: 0 5px;
      min-width: 49px;
      color: var(--new-yoc-ghost-white);
      text-decoration: none;

      &.active,
      &:hover {
        background: var(--new-yoc-secondary-turquoise);
        color: var(--new-yoc-black);
      }
    }
  }

  .menu-button {
    display: block;
    width: 100%;
    border: none;
    outline: none;
    background: none;
    text-align: left;
    padding: 0;
    margin: 0;
    font: bold 14px/34px var(--page-font);
    border-radius: var(--border-radius);
    box-shadow: 0 0 0 2px rgba(242, 245, 250, 0.2) inset;
    cursor: pointer;

    span {
      display: inline-block;
      vertical-align: top;
      min-width: 43px;
      border-radius: var(--border-radius);
      background: var(--new-yoc-secondary-turquoise);
      color: var(--new-yoc-black);
      text-align: center;
      padding: 0 5px;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 12px;
      width: 14px;
      height: 14px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' viewBox='0 0 24 24'%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m5 16 7-7 7 7'/%3E%3C/svg%3E")
        no-repeat;
      transform: translateY(-50%) rotate(180deg);
      transition: transform 0.3s ease;
    }
  }

  &.mobile {
    left: auto;
    right: 24px;
    width: 80px;
    height: 34px;
    background: none;

    ul {
      background: var(--new-yoc-blue);
      display: block;
      max-height: 0;
      transition: max-height 0.3s ease;
      border-radius: 0 0 var(--border-radius) var(--border-radius);
    }

    li {
      display: block;
      padding: 4px 0 0;
    }

    a {
      text-align: left;
      border-radius: 0;
      color: var(--new-yoc-ghost-white);
      padding-left: 16px;

      &.active,
      &:hover {
        background: none;
        color: var(--new-yoc-ghost-white);
        box-shadow: 0 0 0 1px rgba(242, 245, 250, 0.2) inset;
      }
    }

    li:last-child a {
      border-radius: 0 0 var(--border-radius) var(--border-radius);
    }
  }

  &.is-open {
    .menu-button::after {
      transform: translateY(-50%) rotate(0);
    }

    ul {
      max-height: 400px;
    }
  }

  &.compact-view {
    right: auto;
    left: 100%;
    margin-left: 10px;
  }
}
