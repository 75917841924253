@import '../PreviewScreen/variables';

// SLIDES
.holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: 100%;
  :first-child {
    text-align: center;
  }
}

// ??
.vertical-slide-item {
  flex-direction: column;
  mask: linear-gradient(to bottom, rgba(0, 0, 0, 1) 75%, rgba(0, 0, 0, 0) 100%) no-repeat left bottom / 100% 51%,
  linear-gradient(to top, rgba(0, 0, 0, 1) 75%, rgba(0, 0, 0, 0) 100%) no-repeat left top / 100% 51%;
}

.title {
  display: block;
  margin: 2rem 0;
  font: 32px var(--page-font);
  text-align: center;
}

.crop-mobile {
  width: calc($mobileWidth * var(--preview-scale));
  height: calc($mobileHeight * var(--preview-scale));
  overflow: hidden; // TEMP
}

.crop-desktop {
  width: calc($desktopWidth * var(--preview-scale));
  height: calc($desktopHeight * var(--preview-scale));
  overflow: hidden; // TEMP
}

div[class*='slick-slide']:only-child {
  float: none;
}

