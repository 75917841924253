.ant-carousel {
  height: 100%;
}

.slider {
  height: 100%;
  --preview-gallery-scale: 1; /* fallback (default) value */

  .slide-item {
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 4rem;
    text-transform: uppercase;
  }
}

/* tint non active slides */
.ant-carousel .slick-slide:not(.slick-center) {
  opacity: 0.3;
  pointer-events: none; /* disable interaction */
}

.ant-carousel .slick-slider .slick-list {
  transform: scale(var(--preview-gallery-scale)) translateY(-50%);
  transform-origin: 50% 0;

  animation: showScreen 1s ease;
}

/* VERTICAL */
.ant-carousel .slick-vertical .slick-list {
  transform: scale(1) !important;
  transform-origin: 0 50%;
  height: 100vh !important;
}

.ant-carousel .slick-vertical .slick-slide,
.ant-carousel .slick-vertical .slick-slide .slide-item {
  height: 100vh;
}

.ant-carousel .slick-slider:not(.slick-vertical) .slick-list {
  width: 900%;
  left: -400%;

  position: relative;
  top: 50%;
}

/* NAVIGATION */
.gallery-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  line-height: 0;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 100%);
  border-radius: var(--border-radius);
  background: var(--new-yoc-grey);

  button {
    cursor: pointer;
  }

  .dots {
    margin: 0;
    padding: 0;
    display: flex;

    > li {
      position: relative;
      display: inline-block;
      margin: 3px 5px;
      overflow: hidden;
      vertical-align: top;
    }

    > li button {
      display: block;
      width: 12px;
      height: 12px;
      font-size: 0;
      border: 0;
      cursor: pointer;
      border-radius: 50%;
      transition: opacity 0.3s ease, background-color 0.3s ease;
      opacity: 0.3;
    }

    .slick-active button,
    button:focus,
    button:hover {
      opacity: 1;
    }
  }
}

.default {
  .arrow-button {
    background: var(--new-yoc-secondary-turquoise);
    margin: 5px;
    border-radius: 4px;
    flex-shrink: 0;
    --color: var(--new-yoc-blue) !important;
  }

  .dots {
    li button {
      background: var(--new-yoc-light-grey);
    }

    .slick-active button,
    button:focus,
    button:hover {
      background: var(--new-yoc-secondary-turquoise);
    }
  }
}

.neutral {
  .dots {
    li button {
      background: var(--page-color-accent);
    }
  }
}

@keyframes showScreen {
  0%,
  20% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
