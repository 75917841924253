.arrow {
  position: relative;
  width: 32px;
  height: 32px;
  background: none;
  border: 0;
  padding: 0;
  vertical-align: top;
  color: inherit;
  cursor: inherit;
  text-indent: -999px;
  overflow: hidden;
  box-sizing: content-box;
  will-change: transform;
  transition: transform .05s ease-in-out;

  &:focus,
  &:hover {
    color: transparent;
    outline: 0;
  }

  &.right,
  &.left,
  &.top,
  &.bottom {
    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 50%;
      top: 2px;
      border-radius: 1rem;
      background: var(--color);
      width: 2.5px;
      height: 16px;
      transform-origin: right;
      transform: translate(-50%) rotateZ(-45deg);
    }

    &::after {
      transform-origin: left;
      transform: translate(-20%, 60%) rotateZ(45deg);
    }
  }

  &.disabled {
    opacity: .25;
    cursor: not-allowed;
    pointer-events: none;
  }
}

.left {
  transform: scale(var(--scale)) rotateZ(-180deg);
}

.right {
  transform: scale(var(--scale)) rotateZ(0);
}

.top {
  transform: scale(var(--scale)) rotateZ(-90deg);
}

.bottom {
  transform: scale(var(--scale)) rotateZ(90deg);
}
