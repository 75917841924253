@import-normalize;
@import './common/main';

* {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  overscroll-behavior: none;
  visibility: hidden;
}

// quick fix for antd DEV styles (e.g. Gallery)
[class*='css-'][class^='ant-'] {
  font-family: inherit;
  color: inherit;
}
