@font-face {
  font-family: IBMPlexMono;
  src: url('../assets/fonts/ibm-plex-mono.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: MuliLight;
  src: url('../assets/fonts/muli-light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body.default {
  /* Palette */
  --new-yoc-gold: #f7d96c;
  --new-yoc-blue: #14151e;
  --yoc-white: #fbfbfb;
  --new-yoc-black: #111;
  --new-yoc-grey: #20212a;
  --new-yoc-light-grey: #535460;
  --new-yoc-turquoise: #4fb3bd;
  --new-yoc-light-turquoise: #8bcfd6;
  --new-yoc-secondary-turquoise: #d6f0f4;
  --new-yoc-ghost-white: #fbfbfb;

  --border-radius: 4px;

  /* Page */
  --page-font: IBMPlexMono, Arial, Helvetica, sans-serif;
  --page-font-second: MuliLight, Arial, Helvetica, sans-serif;
  --page-font-second-size: clamp(14px, 1.3vw, 24px);
  --page-font-third-size: clamp(12px, 1.3vw, 15px);
  --page-color: var(--yoc-white);
  --page-background: var(--new-yoc-blue);

  /* Device Frames */
  --device-frame-outer-border: #298891;
  --device-frame-inner-background: var(--new-yoc-secondary-turquoise);
  --device-frame-outer-background: #353744;
  --device-frame-inner-details: #b3e3e8;

  color: var(--page-color);
  font: clamp(20px, 4vw, 32px) var(--page-font-second);
  background: var(--page-background);
  visibility: visible;

  .logo {
    top: 13px;
    left: 32px;
    width: 48px;
    height: 48px;
  }

  .logo-large {
    width: 64px;
    height: 64px;
  }

  .ant-modal-content {
    position: relative;
    background: var(--new-yoc-grey);
    text-align: center;
    padding-top: 16vh;

    .ant-modal-body,
    .ant-typography {
      font: clamp(16px, 1.3vw, 24px) / 24px var(--page-font-second);
    }

    .ant-modal-close {
      color: var(--new-yoc-secondary-turquoise);
    }

    .ant-modal-footer > .ant-btn-default {
      display: none;
    }

    .ant-typography {
      color: var(--new-yoc-turquoise);
    }

    &::before {
      content: '';
      position: absolute;
      right: 50%;
      top: 4vh;
      transform: translateX(50%);
      width: 9vh;
      height: 9vh;
      background: url('../assets/solutions-laptop.svg') no-repeat center/contain;
    }
  }

  .desktop-turn {
    /* MODAL for turning the phone */
    .ant-modal-content {
      &::before {
        background: url('../assets/turn-phone.svg') no-repeat center/contain;
        animation: rotate 4s ease infinite;
      }

      .ant-modal-footer {
        text-align: center;
      }

      .ant-btn-primary {
        margin-inline-start: 0;
        box-shadow: none;
        padding: 0 24px;
        background: var(--new-yoc-secondary-turquoise);
        color: var(--new-yoc-blue);
      }
    }

    @keyframes rotate {
      0%,
      20% {
        transform: translateX(50%) rotate(0deg);
      }
      40%,
      50% {
        transform: translateX(50%) rotate(-90deg);
      }
      70%,
      100% {
        transform: translateX(50%) rotate(0deg);
      }
    }
  }

  /* MOBILE MENU */
  &.mobile {
    .back-arrow {
      display: none;
    }

    header[class*='header'] {
      padding-left: 75px;
    }

    .logo {
      left: 20px;
    }

    .tag {
      font-size: 24px;
      white-space: normal;
    }

    /* MODAL ON MOBILE MENU */
    .solutions {
      .ant-typography-copy {
        color: var(--new-yoc-secondary-turquoise);
      }

      .ant-btn-primary {
        display: none;
      }
    }
  }

  /* MOBILE PREVIEW */
  &.preview-mobile {
    .container.mobile {
      width: 100%;
      overflow: hidden;
      bottom: 0;
      top: 60px;
      left: 0;
      right: 0;
    }

    .container.mobile iframe {
      width: 100%;
      height: 100%;
      border: none;
    }

    .back-arrow {
      position: fixed;
      cursor: pointer;
      top: 18px;
      left: 22px;
      width: 24px;
      height: 24px;
      border: none;
      z-index: 99;
      background: url('../assets/back-arrow.svg') no-repeat center/contain;
    }

    .logo,
    .tag,
    nav[class*='nav'] {
      display: none;
    }

    header[class*='header'] {
      background: none;
      width: 100%;
      min-height: 60px;
    }
  }

  .menu-arrow {
    position: absolute;
    right: 0;
  }

  /* DMEXCO */
  &.dmexco {
    -webkit-user-select: none;
    user-select: none;

    nav[class*='nav'] {
      display: none;
    }

    div[class*='page'][class*='mobile'] {
      grid-template-rows: 146px 1fr;
      grid-template-areas: 'header' 'sidebar';
      background: var(--new-yoc-blue);
    }

    .logo {
      display: inline;
      top: 28px;
      left: auto;
      right: 30px;
      width: 90px;
      height: 90px;

      &::before {
        content: none;
      }
    }

    header[class*='header'] {
      background: none;
      padding: 0;
      height: 146px;

      .tag {
        width: 100%;
        text-align: center;
        font: clamp(18px, 5.3vw, 55px) / 2.5em var(--page-font);
      }
    }

    div[class*='page'][class*='mobile'] div[class*='aside'] {
      padding: 0 32px;
    }

    div[class*='title'] {
      font: clamp(18px, 5.3vw, 48px) / 2.5em var(--page-font);
      padding: 0 0 0 26px;
      &:hover {
        color: var(--yoc-white);
      }
      &::after {
        right: 16px;
        transform: translateY(-50%) rotate(180deg) scale(4.5);
      }
    }

    li[class*='expanded'] div[class*='title'] {
      background: linear-gradient(to right, var(--new-yoc-turquoise) 14px, transparent 5px) no-repeat;
      border-radius: 10px;
      padding: 0 60px 0 36px;
      &::after {
        transform: translateY(-50%) rotate(0) scale(4.5);
      }
    }

    li[class*='expanded'] ul[class*='list'] {
      padding: 20px 0;
    }

    .menu-arrow {
      margin: 0 16px 0 0;
    }

    ul[class*='list'] {
      border-radius: 0 0 20px 20px;
    }

    li[class*='item'] {
      font-size: clamp(14px, 3.3vw, 32px);
      margin: 28px 20px 20px;
      padding: 0 30px;
      height: 140px;
      border-radius: 14px;

      &[class*='active'] {
        box-shadow: inset 0 0 0 4px var(--new-yoc-turquoise);
      }
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
      &::before {
        height: 100px;
        width: 180px;
      }
      &::after {
        height: 2px;
        top: -14px;
      }
      &:hover:not([class*='active']) {
        box-shadow: none;
      }
    }

    .dmexco-ad-category {
      position: absolute;
      top: 32px;
      font: clamp(18px, 3.3vw, 55px) / 2.5em var(--page-font);
      white-space: nowrap;
      p {
        margin: 0;
        text-transform: uppercase;
        left: 50%; /* Position the element at the center of the viewport */
        transform: translateX(-50%); /* Shift it left by 50% of its own width */
        position: fixed;
      }
    }

    .back-arrow {
      position: fixed;
      cursor: pointer;
      top: 40px;
      left: 36px;
      width: 64px;
      height: 64px;
      z-index: 99;
      border: none;
      padding: 0;
      background: url('../assets/back-arrow.svg') no-repeat center/contain;
    }

    .frame-container {
      flex-direction: column;
      align-items: center;
    }

    /* Gallery style overwriting */
    .gallery-nav {
      inset: 50% 24px 0 0;
      transform: translate(0, -50%);
      left: auto;
      bottom: auto;
      flex-direction: column;
    }

    .dots {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;

      > li {
        margin: 8px;
      }
    }

    /* Move QR code to the left */
    .qr {
      bottom: 60px;
      right: auto;
      left: 30px;
    }
  }

  @media screen and (orientation: landscape) {
    & {
      .container.desktop {
        inset: 70px 25px 16px;
        max-width: 80%;
        margin: 0 auto;
      }
    }
  }
}
