.switcher {
  position: absolute;
  right: 30px;
  left: 30px;
  top: 16px;
  display: flex;
  justify-content: flex-end;
  gap: 24px;

  .button {
    background: var(--new-yoc-secondary-turquoise);
    border: none;
    border-radius: var(--border-radius);
    color: var(--new-yoc-blue);
    cursor: pointer;
    font: clamp(12px, 2vw, 14px) var(--page-font);
    height: 40px;
    min-width: 78px;
    transition: opacity 0.3s ease;

    &:disabled {
      opacity: 0.3;
      cursor: not-allowed;
      background: var(--new-yoc-grey);
      color: var(--page-color);
    }
  }

  .switch:not(.hidden) {
    display: inline-flex;
    align-items: center;
    -webkit-align-items: center;
    background: var(--new-yoc-grey);
    font: var(--page-font-third-size) var(--page-font);
    border-radius: var(--border-radius);
    gap: 24px;
    padding: 0 24px;
    height: 40px;

    .ant-switch {
      box-shadow: 0 0 0 2px var(--new-yoc-light-grey);

      span[class*='ant-switch-inner'],
      button[class*='ant-switch-checked'] span[class*='ant-switch-inner'] {
        padding: 0 14px;
      }

      span[class*='ant-switch-inner'],
      span[class*='ant-switch-inner-checked'] {
        background: var(--new-yoc-grey);
      }

      div[class*='ant-switch-handle']::before {
        background: var(--new-yoc-turquoise);
      }
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  &.preview {
    justify-content: center;

    .button {
      position: absolute;
      right: 0;
    }
  }

  .neutral & {
    .button {
      border: 2px solid var(--color-basic);
      border-radius: 5px;
      color: var(--color-basic);
      font-size: 13px;

      &.selected {
        background: var(--color-accent);
        border-color: var(--color-accent);
      }

      &:hover {
        background: #fff;
        color: var(--color-accent);
        border-color: var(--color-accent);
      }
    }
  }

  .preview-mobile & {
    right: 0;
    left: 0;
    top: 0;
    background: var(--new-yoc-grey);
    font: var(--page-font-third-size) var(--page-font);

    .switch {
      position: absolute;
      padding: 0;
      height: auto;
      right: 64px;
      top: 20px;
      z-index: 10;
    }

    .ant-switch-handle::before {
      background: var(--new-yoc-blue);
    }

    .ant-switch-inner-checked,
    .ant-switch-inner-unchecked,
    .ant-switch-inner {
      text-align: center;
      color: var(--new-yoc-blue);
      line-height: 22px;
      font-size: var(--page-font-third-size);
      background: var(--new-yoc-secondary-turquoise);
    }
  }
}
