.neutral {
  .qr .ant-qrcode {
    border: 2px solid var(--color-accent);
  }
}

.default,
.dmexco {
  .qr {
    background: var(--yoc-white);
    border-radius: var(--border-radius);
    color: var(--new-yoc-blue);
    text-decoration: none;

    .ant-qrcode {
      border: none;
      background: var(--yoc-white) !important;
    }

    &::before {
      content: 'SCAN ME';
      display: block;
      border-radius: var(--border-radius) var(--border-radius) 0 0;
      margin: 0 0 -5px;
      text-align: center;
      font: 12px var(--page-font);
      position: relative;
      z-index: 1;
    }
  }
}
.dmexco {
  .qr {
    &::before {
      font: 18px var(--page-font);
    }
  }
}
